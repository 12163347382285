<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { getDecodedId } from '@/utils';

import { LocalStorageService } from '@/services/LocalStorageService';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { types as rootTypes } from '@/store/types';

export default {
  name: 'EmailPdfSummaryHandler',
  props: {
    doctorId: {
      type: String,
      required: true
    },
    patientId: {
      type: String,
      required: true
    },
    diagnosticId: {
      type: String,
      required: true
    },
    isDirect: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    })
  },
  mounted() {
    this.handlePdfSummary();
  },
  methods: {
    ...mapActions({
      setPdfSummary: dashTypes.actions.SET_PDF_SUMMARY
    }),
    async handlePdfSummary() {
      const decodedDoctorId = getDecodedId(this.doctorId)[0];
      const decodedPatientId = getDecodedId(this.patientId)[0];
      const decodedDiagnosticId = this.diagnosticId ? getDecodedId(this.diagnosticId)[0] : null;

      const pdfSummary = {
        doctorId: decodedDoctorId,
        patientId: decodedPatientId,
        diagnosticId: decodedDiagnosticId,
        isDirect: this.isDirect
      };

      await this.setPdfSummary(pdfSummary);

      const isAuthenticated = !!LocalStorageService.getDashboardToken();

      if (!isAuthenticated || this.userId !== decodedDoctorId) {
        await this.$router.replace({ name: 'Home' });

        return;
      }

      await this.$router.replace({
        name: 'PatientCard',
        params: { id: decodedPatientId }
      });
    }
  }
};
</script>
